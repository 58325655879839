import { Gender, Phase } from '@my-tomorrows/api';
import { InterventionType } from '../enums/intervention-type.enum';
import { OverallStatus } from '../enums/overall-status.enum';
import { ProtocolType } from '../enums/protocol-type.enum';
import { StudyType } from '../enums/study-type.enum';
import { DistanceUnit } from './search-filter.interface';

export enum SearchFilterParamKeys {
  Country = 'country',
  AlsoSearchFor = 'also_search_for',
  OtherTerms = 'other_terms',
  ProtocolType = 'protocolType',
  Phase = 'phase',
  OverallStatus = 'overallStatus',
  Gender = 'gender',
  Age = 'age',
  RadiusPlaceId = 'radiusPlaceId',
  RadiusDistance = 'radiusDistance',
  RadiusUnit = 'radiusUnit',
  RadiusPlaceName = 'radiusPlaceName',
  ExclusionRules = 'exclusionRules',
  InterventionType = 'interventionType',
  StudyType = 'studyType',
  Condition = 'condition',
  SortField = 'sortField',
  SortDirection = 'sortDirection',
  Profile = 'profile',
  Limit = 'limit',
  Offset = 'offset',
  UserListId = 'list_id',
}

export interface SearchFilterParams {
  [SearchFilterParamKeys.Country]?: string | string[];
  [SearchFilterParamKeys.AlsoSearchFor]?: string | string[];
  [SearchFilterParamKeys.OtherTerms]?: string | undefined;
  [SearchFilterParamKeys.ProtocolType]?: ProtocolType | ProtocolType[];
  [SearchFilterParamKeys.Phase]?: Phase | Phase[];
  [SearchFilterParamKeys.OverallStatus]?: OverallStatus | OverallStatus[];
  [SearchFilterParamKeys.Gender]?: Gender;
  [SearchFilterParamKeys.Age]?: number | string;
  [SearchFilterParamKeys.RadiusPlaceId]?: string;
  [SearchFilterParamKeys.RadiusDistance]?: string | number;
  [SearchFilterParamKeys.RadiusUnit]?: DistanceUnit;
  [SearchFilterParamKeys.RadiusPlaceName]?: string;
  [SearchFilterParamKeys.ExclusionRules]?: 'true' | 'false';
  [SearchFilterParamKeys.InterventionType]?: InterventionType | InterventionType[];
  [SearchFilterParamKeys.StudyType]?: StudyType | StudyType[];
  [SearchFilterParamKeys.Condition]?: string;
  [SearchFilterParamKeys.SortDirection]?: string;
  [SearchFilterParamKeys.SortField]?: string;
  [SearchFilterParamKeys.Limit]?: number;
  [SearchFilterParamKeys.Offset]?: number;
  [SearchFilterParamKeys.Profile]?: string;
  [SearchFilterParamKeys.UserListId]?: string;
}
